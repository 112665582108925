import DataMappingService from '@/services/DataMappingService';

/**
 * Creates a CSV array from an article list
 * The list needs to be in the format of the article list response
 * @param {Array} venueWineList 
 * @returns 
 */
export function createCSVArrayFromVenueWineList(venueWineList) {
	const venueWineDataResponse = {
		'data': venueWineList
	}
	
	var csvArray = []

	const venueWines = DataMappingService.mapVenueWineArrayApiToVenueWineArrayUi(venueWineDataResponse)

	venueWines.forEach((venueWine) => {
		var row = {
			Hersteller: venueWine.wine.winery.title,
			Produktname: venueWine.wine.title,
			Jahrgang: venueWine.year,
			Region: venueWine.wine.region.title,
			Land: venueWine.wine.land.title,
			Kategorie: venueWine.wine.category.title ?? '',
			Farbe: venueWine.wine.wineType.title,
			Sorte: venueWine.wine.grapeVariety.title,
			Anzahl: venueWine.amountFloat,
			EK: venueWine.purchasePriceAvg,
			VK: venueWine.sellingPrice,
			Größe: venueWine.bottleSize.amount,
			Notizen: venueWine.comments,
			Regal: venueWine.shelfNumber
		}
		csvArray.push(row)
	})
	return csvArray
}

export function createCSVArrayFromVenueWineResponse(venueWineDataResponse){
		var csvArray = []

		const venueWines = DataMappingService.mapVenueWineArrayApiToVenueWineArrayUi(venueWineDataResponse)

		venueWines.forEach((venueWine) => {
			var row = {
				Hersteller: venueWine.wine.winery.title,
				Produktname: venueWine.wine.title,
				Jahrgang: venueWine.year,
				Region: venueWine.wine.region.title,
				Land: venueWine.wine.land.title,
				Kategorie: venueWine.wine.category.title ?? '',
				Farbe: venueWine.wine.wineType.title,
				Sorte: venueWine.wine.grapeVariety.title,
				Anzahl: venueWine.amountFloat,
				EK: venueWine.purchasePriceAvg,
				VK: venueWine.sellingPrice,
				Größe: venueWine.bottleSize.amount,
				Notizen: venueWine.comments,
				Bezugsquelle: venueWine.purchasePrices && venueWine.purchasePrices.length > 0 ? venueWine.purchasePrices[0].wineSupplier.title : 'nicht vorhanden',
				Regal: venueWine.shelfNumber
			}
			csvArray.push(row)
		})
		return csvArray
}

export function writeArrayToCSVDownload(array, fileName, papaParse) {
	var csv = papaParse.unparse(array);
	var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
	var csvURL =  null;
	if (navigator.msSaveBlob)
	{
			csvURL = navigator.msSaveBlob(csvData, fileName + '.csv');
	}
	else
	{
			csvURL = window.URL.createObjectURL(csvData);
	}

	var tempLink = document.createElement('a');
	tempLink.href = csvURL;
	tempLink.setAttribute('download', fileName + '.csv');
	tempLink.click();
}